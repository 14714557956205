import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';
import { getAccessType } from '../store/slices/application';
import { useSelector } from 'react-redux';
import { getUser } from '../store';

interface Props {
    fetchData?: boolean;
    fetchDataOptions?: boolean;
    orgId?: string;
}

const useAdvertisingCampaigns = ({ fetchData = false, fetchDataOptions = false, orgId }: Props) => {
    const [advertisingCampaigns, setAdvertisingCampaigns] = useState<AdvertisingCampaign[]>();
    const [advertisingContentOptions, setAdvertisingContentOptions] = useState<SelectOption[]>([]);
    const [advertisingUFCityOptions, setAdvertisingUFCityOptions] = useState<SelectOption[]>([]);
    const [advertisingSegmentOptions, setAdvertisingSegmentOptions] = useState<SelectOption[]>([]);
    const [campaignInformation, setCampaignInformation] =
        useState<AdvertisingCampaignInformation>();

    const { get, post, patch, _delete, loading, error } = useRequests();

    const user = useSelector(getUser);
    const accessType = useSelector(getAccessType);

    const fetchAdvertisingCampaigns = async ({ page, search }: FetchParams = {}) => {
        let route = '/partner/advertising-campaigns?';

        if (accessType === 'POINT') {
            route += `pointId=${user?.id}&`;
        } else {
            route += `orgId=${orgId}&`;
        }

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;

            setAdvertisingCampaigns(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchContentOptions = async () => {
        let route = `/partner/advertising-campaigns/content-options?`;

        if (accessType === 'POINT') {
            route += `pointId=${user?.id}&`;
        } else {
            route += `orgId=${orgId}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data as { id: string; name: string; duration: number }[];

            const contentOptions = data?.map((content) => ({
                value: content.id,
                label: content.name,
                duration: content.duration,
            }));

            setAdvertisingContentOptions(contentOptions);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchUFCityOptions = async () => {
        let route = `/partner/advertising-campaigns/uf-city-options?orgId=${orgId}`;

        try {
            const response = await get(route);
            const data = response?.data as { id: string; name: string }[];

            const contentOptions = data?.map((content) => ({
                value: content.id,
                label: content.name,
            }));

            setAdvertisingUFCityOptions(contentOptions);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchSegmentOptions = async () => {
        let route = `/partner/advertising-campaigns/segment-options`;

        try {
            const response = await get(route);
            const data = response?.data as { id: string; name: string }[];

            const contentOptions = data?.map((content) => ({
                value: content.id,
                label: content.name,
            }));

            setAdvertisingSegmentOptions(contentOptions);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const calculateCampaignInformation = async (_payload: any) => {
        let route = '/partner/advertising-campaigns/calculate-campaign-information';

        const payload = {
            ..._payload,
            org_id: orgId,
        };

        if (accessType === 'POINT') {
            delete payload.org_id;
        }

        try {
            const response = await post(route, payload);
            setCampaignInformation(response?.data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            console.log('failed to fetch campaign information:', errorMessage);

            // toast.error(errorMessage);
            // throw err;

            setCampaignInformation(undefined);
        }
    };

    const resetCampaignInformation = () => {
        setCampaignInformation(undefined);
    };

    const createAdvertisingCampaign = async (_payload: any) => {
        let route = '/partner/advertising-campaigns';

        const payload = {
            ..._payload,
            org_id: orgId,
        };

        if (accessType === 'POINT') {
            delete payload.org_id;
        }

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Campanha de publicidade criada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateAdvertisingCampaign = async (id: string, payload: any) => {
        let route = `/partner/advertising-campaigns/${id}`;

        if (accessType === 'POINT') {
            delete payload.org_id;
        }

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Campanha de publicidade alterada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteAdvertisingCampaign = async (id: string) => {
        let route = `/partner/advertising-campaigns/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Campanha de publicidade removida com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (!orgId) return;

        if (fetchData) {
            fetchAdvertisingCampaigns();
        }

        if (fetchDataOptions) {
            fetchContentOptions();
            fetchUFCityOptions();
            fetchSegmentOptions();
        }

        // eslint-disable-next-line
    }, []);

    return {
        advertisingCampaigns: advertisingCampaigns,
        advertisingContentOptions,
        advertisingUFCityOptions,
        advertisingSegmentOptions,
        campaignInformation,
        resetCampaignInformation,
        calculateCampaignInformation,
        fetchAdvertisingCampaigns,
        createAdvertisingCampaign,
        updateAdvertisingCampaign,
        deleteAdvertisingCampaign,
        error,
        loading,
    };
};

export default useAdvertisingCampaigns;
