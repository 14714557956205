import { FC, ReactNode } from 'react';
import { isoDateToBrl } from '../utils/date.utils';

interface Children {
    children?: ReactNode;
    className?: string;
}

export const FormSection: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

export const FormDescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

export const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">{children}</div>;
};

export const FormLine: FC<Children> = ({ children, className }) => {
    return <div className={`flex gap-6 w-full max-w-sm ${className}`}>{children}</div>;
};

export const DateIntervalComponent: FC<{ start: string; end: string }> = ({ start, end }) => {
    const start_date = isoDateToBrl(start?.split('T')[0]);
    const end_date = isoDateToBrl(end?.split('T')[0]);

    return (
        <>
            {start_date} ~ {end_date}
        </>
    );
};
