import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { intSecondsToFormattedMinutes } from '../../../common/utils/date.utils';
import { payloadFromSchema } from './CreateAdvertisingCampaign';

interface Props {
    control: any;
    watch: any;
    setValue: any;
    schema: any;
    campaignInformation: any;
    calculateCampaignInformation: any;
    resetCampaignInformation: any;
}

const useAdvertisingCampaignForm = ({
    control,
    watch,
    setValue,
    schema,
    campaignInformation,
    calculateCampaignInformation,
    resetCampaignInformation,
}: Props) => {
    const { fields } = useFieldArray({
        control,
        name: 'advertising_campaign_content',
    });

    const advertising_campaign_content = watch('advertising_campaign_content', fields);

    const setContentTime = (ind: number) => {
        if (advertising_campaign_content) {
            const content = advertising_campaign_content[ind];

            if (content) {
                const duration = (content.content_id as any)?.duration || 0;
                const display_duration = intSecondsToFormattedMinutes(duration, '');
                setValue(`advertising_campaign_content.${ind}.display_duration`, display_duration);
            }
        }
    };

    // calculate campaign information
    const period = watch('campaign_period');
    const value = watch('campaign_value');
    const content = watch('advertising_campaign_content');
    const uf = watch('segment_city_state');
    const segment = watch('segment_segment_location');
    const exposition = watch('max_display_by_hour');

    const verifyAndCalcualteCampaignInformation = async () => {
        const formData = watch();
        try {
            await schema.validate(formData);
            const payload = payloadFromSchema(formData as any);
            calculateCampaignInformation(payload);
        } catch (err: any) {
            console.log('skipping... form not ready:', err?.message);
            if (campaignInformation) {
                resetCampaignInformation();
            }
        }
    };

    useEffect(() => {
        verifyAndCalcualteCampaignInformation();
        // eslint-disable-next-line
    }, [period, value, content, uf, segment, exposition]);

    return { setContentTime, triggerCalculate: verifyAndCalcualteCampaignInformation };
};

export default useAdvertisingCampaignForm;
